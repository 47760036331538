@use 'sass:map';
@import './colors.scss';

// You must @use this file in every scss file you want to use the color() function in.

@function color($key) {
  @if map.has-key($colors, $key) {
    @return map.get($colors, $key);
  } @else {
    @error "Error: The color `#{$key}` does not exist in the color map.";
  }
}

:root {
  // Create CSS variables for each color
  @each $name, $color in $colors {
    --color-#{"#{$name}"}: #{$color};
  }
}

$font: Titillium Web;

$mobile_width: 912px;
$menubar_breakpoint: 1080px;

$font_size_xs: 10px;
$font_size_s: 11px;
$font_size_m: 12px;
$font_size_l: 13px;
$font_size_xl: 14px;
$font_size_xxl: 16px;

$font_size_icon: 18px;
$font_size_icon_bigger: 22px;

$font_size_title_s: 20px;
$font_size_title_m: 24px;
$font_size_title_l: 26px;
$font_size_title_xl: 28px;

$sidebar_padding: 25px;
