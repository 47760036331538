@use 'src/styles' as *;

#skillhop-app .mat-mdc-button {
  --mdc-text-button-label-text-tracking: 0;
  &.color-secondary {
    --mdc-text-button-label-text-color: #{color(color_secondary)};
  }
}

[class*='btn-small'] {
  padding: 0;
  line-height: unset;
  font-size: inherit;
  font-weight: inherit;

  .mat-button-focus-overlay {
    display: none;
    border: none;
  }
}

[class^='btn'],
.mat-mdc-button {
  cursor: pointer;
  transition: filter 0.2s ease-in-out;
}

[class*='btn-small']:focus {
  filter: brightness(1);
}

// BuildEvo Button
.be-button {
  @apply block rounded-xl w-full bg-navy_700;
  @apply box-border p-3;
  @apply text-white text-center text-lg no-underline;
  @apply hover:cursor-pointer hover:bg-blue_500 transition-colors;

  &:disabled,
  &[disabled] {
    @apply bg-grey_400 cursor-not-allowed;
  }
}

// ----------------------------
// TODO: check if this button is still in use, delete if not
// ----------------------------
#skillhop-app .landing-btn {
  cursor: pointer;
  font-family:
    Titillium Web,
    sans-serif !important;
  height: 34px;
  padding: 0 24px;
  color: white;
  border-radius: 10px;
  line-height: 1;
  font-weight: 600;
  transition: 150ms all ease-in-out;
  background: transparent;
  border: 1px solid transparent;
  @apply text-size_xxl sm:text-size_icon whitespace-nowrap;
  &.cubic {
    border-radius: 3px;
  }
  &.btn-disabled {
    background-color: color(shade_6) !important;
    color: color(shade_3);
    &:hover {
      filter: none;
    }
  }

  &.btn-smaller {
    height: 22px;
    padding: 0 10px;
  }

  &.btn-small {
    height: 28px;
    padding: 0 16px;
  }
  &.btn-height-big {
    height: 42px; // the answer to life, the universe and everything
  }
  &.btn-big {
    height: 42px; // the answer to life, the universe and everything
    padding: 0 48px;
  }
  &.btn-big-full {
    height: 42px; // the answer to life, the universe and everything
    padding: 0 12px;
  }
  &.light-blue-btn {
    background-color: color(color_secondary);
  }
  &.outline-btn {
    border: 1px solid color(color_secondary);
    color: color(color_secondary);
  }
  &.outline-shade-1-btn {
    background-color: color(color_primary);
    border: 1px solid color(shade_1);
    &:hover {
      filter: brightness(1.2);
    }
  }
  &.outline-primary-btn {
    background-color: color(color_primary);
    border: 2px solid color(color_secondary);
  }
  &.outline-white-btn {
    background-color: transparent;
    border: 1px solid white;
    color: white;
    &:hover {
      border-color: color(shade_4);
      color: color(shade_4);
    }
  }
  &.light-red-btn {
    background-color: color(color_red_light);
  }
  &.red-btn {
    border: 4px solid color(color_red_light);
    background-color: color(red_500);
    &:hover {
      filter: none;
      border-color: color(red_500);
    }
  }
  &.yellow-btn {
    background-color: color(color_accent);
  }

  &:hover {
    filter: brightness(1.1);
  }
}
