@use 'src/styles' as *;

// BuildEvo Link
.be-link {
  @apply relative p-0 text-lg font-mont font-semibold cursor-pointer border-[none] bg-transparent no-underline;

  @apply transition-colors duration-[500ms];
  &:hover,
  &.active {
    color: color(blue_500) !important;
  }

  &.active {
    @apply underline underline-offset-[1rem];
  }

  &:disabled,
  &[disabled],
  &.disabled {
    color: color(grey_400) !important;
    @apply cursor-not-allowed;
  }
}
