@use 'src/styles' as *;
@use 'sass:color';

// here we overwrite the default styles of Material components
// #skillhop-app id's are used to get a higher css selector specificity

// mat input elements

mat-form-field {
  input {
    color: color(color_primary);
    line-height: 1rem;
    //color: color(shade_1);
  }

  --mat-form-field-container-text-tracking: 0;
  --mat-form-field-container-height: 30px;
  --mat-form-field-container-vertical-padding: 0px;
  --mdc-filled-text-field-input-text-placeholder-color: #{color(shade_3)};
  --mat-form-field-container-text-line-height: 30px;
  --mat-form-field-container-text-size: 14px;

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background: none;
  }
  .mat-mdc-form-field-focus-overlay {
    background: none;
  }

  .mdc-text-field--outlined,
  .mdc-text-field--no-label {
    .mat-mdc-form-field-infix {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }

  // border settings
  --mdc-filled-text-field-active-indicator-color: #{color(shade_3)};
  &.borderless {
    .mdc-line-ripple::before,
    .mdc-line-ripple::after {
      border: none;
    }
  }

  &.no-preview {
    .mat-mdc-select-value {
      visibility: hidden;
      height: 10px;
    }
  }
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(203, 215, 247, 0.9) !important;
  height: 1px;
}

.ng-invalid .mat-form-field.mat-focused .mat-form-field-ripple {
  color: red;
}

.mat-form-field-ripple {
  background-color: color(color_secondary);
  opacity: 1;
  transform: scaleX(1);
  transition: width 0.4s ease-in;
  width: 10%;
}

.mat-form-field-suffix {
  color: color(shade_4);
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  transform: scaleX(1);
  width: 100%;
}

.mat-form-field-appearance-legacy .mat-form-field-ripple {
  height: 1px;
}

.mat-form-field-subscript-wrapper {
  position: absolute;
  top: -0.6em !important;
  margin-top: 0.5416666667em !important;
  text-align: right;
  text-transform: uppercase;
}

// ------------------------------------
// mat select and mat option
// ------------------------------------
#skillhop-app {
  --mat-select-trigger-text-tracking: 0;
  --mat-option-label-text-tracking: 0;
}
mat-form-field {
  .mat-mdc-select {
    --mat-select-trigger-text-size: 14px;
    --mat-select-trigger-text-weight: 700;
    --mat-select-enabled-arrow-color: #{color(shade_4)};
  }
  .mdc-text-field {
    padding: 0 4px;
  }
}
#skillhop-app div.mat-mdc-select-panel {
  min-width: 140px;
}

#skillhop-app .mat-mdc-option {
  min-height: 40px;
  --mat-option-label-text-color: #{color(shade_3)};
  //--mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.4);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.12);

  .mat-primary
    .mat-option.mdc-list-item--selected:not(.mdc-list-item--selected) {
    color: color(color_primary);
  }

  &:not(.mat-mdc-option-multiple) {
    .mat-pseudo-checkbox {
      display: none;
    }
  }
}

.mat-select-arrow {
  // for compatibility reasons, this is used to display a dropdown arrow
  width: 0;
  height: 0;
  border-left: 5px solid rgba(0, 0, 0, 0);
  border-right: 5px solid rgba(0, 0, 0, 0);
  border-top: 5px solid;
  margin: 0 4px;
}

// ------------------------------------
// mat slide toggle
// ------------------------------------
#skillhop-app mat-slide-toggle.mat-mdc-slide-toggle {
  // disabled
  --mdc-switch-disabled-unselected-handle-color: #{color(shade_2)};
  --mdc-switch-disabled-unselected-track-color: #{color(shade_4)};
  --mdc-switch-disabled-track-opacity: 0.38;
  --mdc-switch-disabled-handle-opacity: 0;

  --mdc-switch-handle-surface-color: #{color(shade_2)};

  // unselected and not disabled
  --mdc-switch-unselected-handle-color: #{color(shade_2)};
  --mdc-switch-unselected-hover-handle-color: #{color(shade_2)};
  --mdc-switch-unselected-track-color: #{color(shade_4)};

  // selected and not disabled
  --mdc-switch-selected-handle-color: #{color.mix(
      white,
      color(color_accent),
      16%
    )};
  --mdc-switch-selected-track-color: #{color.mix(
      white,
      color(color_primary),
      16%
    )};
  --mdc-switch-selected-hover-handle-color: #{color(color_accent)};
  --mdc-switch-selected-hover-track-color: #{color(color_primary)};

  .mdc-switch__icons {
    visibility: hidden;
  }
  .mdc-switch--disabled {
    --mdc-switch-handle-surface-color: #{color.mix(white, color(shade_2), 56%)};
  }
}

// checkbox
// checkbox style + on hover
$checkbox-border-radius: 5px;
#skillhop-app .mdc-checkbox {
  // ripple states
  --mdc-checkbox-selected-focus-state-layer-opacity: 0;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0;

  // checkbox overall size
  --mdc-checkbox-state-layer-size: 24px;
  .mat-ripple {
    display: none;
  }

  .mdc-checkbox__background {
    border: 2px solid color(color_secondary);
    box-sizing: border-box;
    border-radius: $checkbox-border-radius;
  }

  .mdc-checkbox__checkmark,
  .mdc-checkbox__mixedmark {
    opacity: 0;
  }

  .mdc-checkbox__background:after {
    position: absolute;
    color: white;
    opacity: 1;

    font-family: 'fw-icons';
    font-size: 18px;
    font-weight: normal;
    content: '\e807'; // checkmark

    transition: all 0.2s ease-in; //cubic-bezier(.25, .8, .25, 1), box-shadow 280ms cubic-bezier(.4, 0, .2, 1);
  }

  //.mat-mdc-checkbox-checked {
  //  .mdc-checkbox__background:after {
  //    font-size: medium;
  //    opacity: 1;
  //  }
  //}

  .mat-pseudo-checkbox {
    display: block;
  }
}

// UNCOMMENT IF NEEDED

//.mat-mdc-slide-toggle-content {
//  font-family: 'Titillium Web';
//  color: color(color_primary);
//  font-weight: bolder;
//  margin-bottom: 0.17rem;
//}

//.mat-mdc-pseudo-checkbox-checked {
//  background-color: color(white);
//
//  &:after {
//    color: color(color_secondary);
//  }
//}

//.mat-checkbox-background {
//  border-radius: $checkbox-border-radius !important;
//}
//
//.mat-checkbox-checked.mat-accent .mat-checkbox-background {
//  background-color: color(color_secondary);
//}
//
//.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent
//  .mat-ripple-element,
//::ng-deep
//  .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent
//  .mat-ripple-element {
//  background: color(color_secondary);
//}
//
//.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
//.mat-checkbox-checked.mat-accent .mat-checkbox-background {
//  background: color(color_secondary);
//}
//
//// pseudo checkbox
//
.mat-pseudo-checkbox {
  border: 1px solid color(shade_4) !important;
  box-sizing: border-box !important;
  border-radius: $checkbox-border-radius !important;
}

.mat-pseudo-checkbox-checked {
  border-color: transparent !important;
  background-color: color(color_secondary) !important;
}

.mat-pseudo-checkbox-checked:after {
  justify-content: center;
  align-items: center;
  display: flex;

  position: absolute;
  color: white;
  opacity: 1;

  font-family: 'fw-icons';
  font-size: 14px;
  font-weight: normal;
  content: '\e807'; // checkmark

  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  background-color: unset;
  border-bottom: none;
  border-left: none;
  transform: none;
  transition: all 0.2s ease-in; //cubic-bezier(.25, .8, .25, 1), box-shadow 280ms cubic-bezier(.4, 0, .2, 1);
}

/// datepicker

.mat-calendar-table-header tr th {
  font-family: Titillium Web;
  color: color(shade_3);
  padding: 1rem 0;
}

.mat-calendar-table-header-divider {
  display: none;
}

[aria-hidden='true'] .mat-calendar-body-label {
  display: none;
}

td.mat-calendar-body-label {
  visibility: hidden;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  background-color: color(shade_4) !important;
}
.cdk-keyboard-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.cdk-program-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  background-color: color(shade_6) !important;
}

.mat-calendar-body-disabled
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ) {
  background-color: color.scale(color(grey_800), $lightness: 95%);
}

.mat-calendar-body-cell-content {
  font-family: Titillium Web;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 12px;
  background-color: color(shade_7);
  color: color(shade_3);

  border-radius: 5px !important;

  &.mat-calendar-body-selected {
    background-color: color(color_secondary);
    color: color(white);
    font-weight: bold;
  }

  &.mat-calendar-body-today {
    border: 2px solid color(shade_1) !important;
    box-shadow: none;
    color: color(color_primary);
  }
}

.mat-datepicker-content .mat-calendar {
  height: auto !important;
  transition: 0.2s ease-in;
}

.mat-calendar-controls {
  color: color(color_primary);
}

.mat-calendar-arrow,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: color(shade_3);
}

.mat-datepicker-toggle {
  color: color(shade_4);
}

// calendar event day
.mat-calendar-body-cell-content {
  &.schedule-day {
    font-weight: bold;
    color: black;
    background-color: color(white);
    border: 2px solid color(color_secondary) !important;
  }

  &.event-day {
    font-weight: bold;
    color: black;
    background-color: color(white);
    border: 2px solid color(color_secondary) !important;

    .day-event-nr {
      position: absolute;
      text-align: right;
      margin-top: 2%;
      padding-right: 20%;
      height: 100%;
      width: 100%;

      font-weight: bold;
      font-size: 10px;
      color: color(shade_2);
    }
  }

  &.mat-calendar-body-selected {
    background-color: color(shade_1);
    color: color(white);
    font-weight: bold;
    border: 2px solid color(shade_1) !important;

    .day-event-nr {
      color: color(shade_4);
    }
  }
}

.mat-button-wrapper {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
}

.mat-slide-toggle .mat-slide-togwgle-ripple {
  top: calc(50% - 6px);
  left: calc(50% - 6px);
  height: 12px;
  width: 12px;
}

html {
  --mat-datepicker-calendar-date-text-color: #{color(shade_3)};
  --mat-datepicker-calendar-text-size: #{$font_size_xxl};
  --mat-datepicker-calendar-date-today-selected-state-outline-color: transpatent;
}

// drawer backprop disable
.mat-drawer-backdrop.mat-drawer-shown {
  background-color: transparent !important;
}

// ------------------------------------
// mat menu
// ------------------------------------
#skillhop-app {
  --mat-menu-item-label-text-size: 14px;
  --mat-menu-item-label-text-tracking: 0;
}
