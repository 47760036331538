/*
   Animation example, for spinners
*/
.animate-spin {
  -moz-animation: spin-own 2s infinite linear;
  -o-animation: spin-own 2s infinite linear;
  -webkit-animation: spin-own 2s infinite linear;
  animation: spin-own 2s infinite linear;
  display: inline-block;
}

.animate-spin-1s {
  animation: spin-own 1s infinite linear;
  display: inline-block;
}

.upload-drop-area > * {
  transition: 0.5s opacity;
}

.animate-shake {
  animation: 0.7s shake infinite;
  position: relative;
  & > * {
    opacity: 0; // hide the children to animate their return
  }
}

.animate-shake:before {
  opacity: 1;
  background: white;
  position: absolute;
  margin: 6px;
  content: '';
  width: 100%;
  left: -5px;
  top: -3px;
  height: 100%;
  z-index: 1;
}

.animate-shake:after {
  opacity: 1;
  position: absolute;
  margin: 15px;
  border: 2px dashed lightblue;
  border-radius: 5px;
  content: 'Upload';
  width: calc(100% - 30px);
  height: calc(100% - 30px);
  left: 0;
  top: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
  font-size: 18px;
  color: #2c427e;
}

.animate-pulsating-border {
  will-change: auto;
  animation: 2s pulsating-border infinite;
  display: inline-block;
  border-radius: 50%;
  /*width: 26px;*/
  height: 35px;
  line-height: 35px;
}

@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(-359deg);
    -o-transform: rotate(-359deg);
    -webkit-transform: rotate(-359deg);
    transform: rotate(-359deg);
  }
}

@-webkit-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(-359deg);
    -o-transform: rotate(-359deg);
    -webkit-transform: rotate(-359deg);
    transform: rotate(-359deg);
  }
}

@-o-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(-359deg);
    -o-transform: rotate(-359deg);
    -webkit-transform: rotate(-359deg);
    transform: rotate(-359deg);
  }
}

@-ms-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(-359deg);
    -o-transform: rotate(-359deg);
    -webkit-transform: rotate(-359deg);
    transform: rotate(-359deg);
  }
}

@keyframes spin-own {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(-359deg);
    -o-transform: rotate(-359deg);
    -webkit-transform: rotate(-359deg);
    transform: rotate(-359deg);
  }
}

@-webkit-keyframes pulsating-border {
  0% {
    -webkit-box-shadow: 0 0 0 0 lightblue;
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px lightblue;
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 lightblue;
  }
}

@keyframes pulsating-border {
  0% {
    -moz-box-shadow: 0 0 0 0 lightblue;
    box-shadow: 0 0 0 0 lightblue;
  }
  70% {
    -moz-box-shadow: 0 0 0 10px lightblue;
    box-shadow: 0 0 0 10px lightblue;
  }
  100% {
    -moz-box-shadow: 0 0 0 0 lightblue;
    box-shadow: 0 0 0 0 lightblue;
  }
}

$rotate_deg: 0.5deg;

@keyframes shake {
  0% {
    transform: translate(-1px, -1px) rotate(-$rotate_deg);
  }
  25% {
    transform: translate(1px, -1px) rotate($rotate_deg);
  }
  50% {
    transform: translate(1px, 1px) rotate(-$rotate_deg);
  }
  75% {
    transform: translate(-1px, 1px) rotate($rotate_deg);
  }
}

@keyframes pulse-opacity {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.85;
  }
  100% {
    opacity: 1;
  }
}

@keyframes pulse-opacity-faded {
  0% {
    opacity: 0.85;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.85;
  }
}

@keyframes shine-and-pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
    background-position-x: -200%;
  }
}
