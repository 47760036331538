@use 'src/styles' as *;
@use 'src/assets/styles/fw-icons' as *;
@use 'src/assets/styles/globalMixins' as globalMixins;

.prime-progress-bar {
  .p-slider.p-slider-horizontal {
    height: 12px;
    border-radius: 3px;
    background: color(white);

    &.p-disabled {
      // make it look like it's not disabled
      opacity: 1;
      overflow: hidden;
    }

    &:before {
      display: flex;
      position: absolute;
      width: 100%;
      height: 100%;
      content: '';
      border: 1px solid color(shade_4);
      box-sizing: border-box;
      top: 0;
      border-radius: 3px;
    }

    &:after {
      content: attr(data-content);
      font-size: 8px;
      position: absolute;
      user-select: none;
      left: 10px;
      text-transform: uppercase;
      pointer-events: none;
      color: color(shade_3);
      font-weight: 700;
    }

    .p-slider-handle {
      width: 13px;
      height: 13px;
      top: 8px;
      border-radius: 50%;
      background: color(color_secondary);
      border: 1px solid color(white);
      outline: 1px solid color(color_secondary);
      z-index: 3;

      &:focus {
        outline: 1px solid color(color_secondary);
        box-shadow: none !important;
      }
    }

    //the bar itself that shows progress with color, not to be confused with the handle
    .p-slider-range {
      // set by the component in ts
      background: color(color_secondary);
      overflow: hidden;
      border-radius: 3px;
      z-index: 2;

      &:after {
        content: attr(data-content);
        font-size: 8px;
        position: absolute;
        user-select: none;
        left: 10px;
        top: 0;
        text-transform: uppercase;
        pointer-events: none;
        color: color(white);
        font-weight: 700;
        z-index: 2;
      }
    }
  }

  &.hide-border-left {
    .p-slider.p-slider-horizontal {
      //border-left: 1px solid transparent;
    }
  }

  &.hide-border-right {
    .p-slider.p-slider-horizontal {
      //border-right: 1px solid transparent;
    }
  }

  &.hideHandler {
    .p-slider-handle {
      display: none;
    }
  }

  &.progress-default-color .p-slider.p-slider-horizontal .p-slider-range {
    background: color(color_secondary);
  }

  &.progress-over-committed-color
    .p-slider.p-slider-horizontal
    .p-slider-range {
    background: color(color_red_light);
  }

  &.progress-complete-color .p-slider.p-slider-horizontal .p-slider-range {
    background: color(color_accent);
  }
}

// -------------------------
// general styles
// -------------------------
// using an ID to make it more specific
#skillhop-app .p-inputtext {
  // this is present in input and dropdown too
  font-family: 'Titillium Web', sans-serif !important;
  font-size: 14px;
  line-height: 1 !important;
  padding: 12px 10px;
  font-weight: 600;
}

// used for padding
#skillhop-app {
  // put the icon to the correct place
  .p-input-icon-right > .p-icon-wrapper,
  .p-input-icon-right > i:last-of-type {
    right: 0.5rem;
  }

  // select the input which has an icon sibling and their parent is a .floating-input
  .floating-input:has(> i.icon) > input.p-inputtext {
    // bigger padding for the input with icon
    padding-right: 26px;
  }
}

// sizing and border color
.p-inputtext,
.p-dropdown,
.p-multiselect {
  height: 40px;
  box-sizing: border-box;
  border-color: color(shade_5);
  // necessary otherwise content will overflow
  width: 100%;
  font-size: 14px;
  font-weight: 600;
}

.spend-distribution-wrapper .lines-wrapper {
  .p-inputtext,
  .p-dropdown {
    height: 38px;
  }
}

.p-float-label input:focus ~ label,
.p-float-label input.p-filled ~ label,
.p-float-label textarea:focus ~ label,
.p-float-label textarea.p-filled ~ label,
.p-float-label .p-inputwrapper-focus ~ label,
.p-float-label .p-inputwrapper-filled ~ label {
  text-transform: uppercase;
  left: 0;
  font-weight: 600;
  font-size: 10px;
  margin-top: -5px;
}
.p-float-label {
  & > label {
    font-size: 14px;
    line-height: 1;
    margin-top: -6px;
    color: color(shade_2);
  }
}

.p-float-label .p-error {
  position: absolute;
  pointer-events: none;
  margin-top: -0.5rem;
  transition-property: all;
  transition-timing-function: ease;
  line-height: 1;
  top: -7px;
  right: 0;
  font-size: 10px;
}

// -------------------------
// input styles
// -------------------------

textarea.p-inputtext,
input.p-inputtext {
  &::placeholder {
    color: color(shade_3);
    opacity: 1;
  }
  &:not(:focus):has(~ label)::placeholder {
    // inputs which have a label sibling and are not focused
    opacity: 0;
  }
  &.ng-invalid.ng-touched::placeholder {
    color: #e24c4c; // primeNG error color
  }
  &:enabled:focus {
    box-shadow: none;
  }

  & > label {
    color: color(shade_3);
    &.disabled {
      color: color(color_grey);
    }
  }

  &:-webkit-autofill ~ label {
    text-transform: uppercase;
    left: 0;
    font-weight: 600;
    top: -0.75rem;
    font-size: 12px;
    font-family: 'Titillium Web', sans-serif;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: all 0s 5000s;
    // override browser's autofill styles
    // technically setting a transition with 5000s delay from our styles to the browser's autofill styles
    // see https://stackoverflow.com/questions/71640408/trouble-with-input-styling-and-autofill/71682924#comment136097872_71682924
  }
}

.floating-input {
  // floating-input is our custom class
  &.p-float-label input {
    // p-float-label is a primeNG class
    &::placeholder {
      opacity: 0;
    }
    &:focus {
      &::placeholder {
        opacity: 1;
      }
    }
  }
}

.p-float-label,
.floating-input {
  i {
    // this needs to be here, otherwise icons for ngPrime it will not be centered
    top: 16px;
  }
}

textarea.p-inputtext {
  height: 146px;
}

.mod-spend-bg .floating-input,
.mod-spend-bg {
  input {
    background: color(white);
    &:disabled {
      background: color(shade_7);
      opacity: 1;
    }
  }
}

.mod-spend-bg .p-dropdown {
  background: color(white);
  &:disabled,
  &.p-disabled {
    background: color(shade_7);
    opacity: 1;
  }
}

.committed-mod-spend-bg .floating-input,
.committed-mod-spend-bg {
  input {
    background: white;
    &:disabled {
      background: color(color_accent_shade_4);
      opacity: 1;
    }
  }
}

.committed-mod-spend-bg .p-dropdown {
  background: white;
  &:disabled,
  &.p-disabled {
    background: color(color_accent_shade_4);
    opacity: 1;
  }
}

.app-custom-input:has(.disabled) {
  background: getColor(shade_7);
  opacity: 1;
}

.app-custom-input.commitment-input:has(.disabled) {
  background: color(color_accent_shade_4);
}

// -------------------------
// dropdown styles
// -------------------------
.p-dropdown:not(.p-disabled).p-focus,
.p-multiselect:not(.p-disabled).p-focus {
  box-shadow: none;
}

.p-dropdown .p-dropdown-trigger,
.p-multiselect .p-multiselect-trigger {
  width: 32px;
}

#skillhop-app p-dropdown,
#skillhop-app p-multiSelect {
  color: color(shade_1);
  .p-dropdown-label {
    font-weight: 700;
    color: color(shade_1);
    &.p-inputtext {
      display: flex;
      align-items: center;
    }
    // if label has no children, append a hidden string to retain height
    &:not(:has(*))::after {
      // don't be afraid, my friend, this is not a forgotten random string
      // it's a hack to retain height of the empty dropdown if it's used with formControl
      content: 'l';
      visibility: hidden;
    }
  }

  .p-overlay {
    margin-top: 10px;
  }

  &.options-centered {
    .p-dropdown-item,
    .p-multiselect-item {
      display: flex;
      justify-content: center;
    }
  }

  &.view-selector {
    .p-dropdown {
      border: none;
      background: transparent;
    }
    .p-placeholder {
      color: color(color_secondary);
    }

    &.opened {
      .p-dropdown {
        background: color(shade_7);
      }
    }

    &.has-value {
      .p-dropdown {
        background: color(shade_7);
      }
      .p-placeholder {
        color: color(shade_1);
      }
    }
  }

  .p-checkbox {
    width: 20px;
    height: 20px;
    .p-checkbox-box {
      width: 20px;
      height: 20px;
    }
  }
}

#skillhop-app {
  // these need to be global, because they are not used inside the component but in the cdk overlay container
  .p-dropdown-panel .p-dropdown-items,
  .p-multiselect-panel .p-multiselect-items {
    padding: 0;
  }

  p-dropdownitem .p-dropdown-item {
    &:has(.disabled) {
      cursor: not-allowed;
      pointer-events: none;
    }
  }

  .p-multiselect-item,
  .p-dropdown-item {
    font-size: 12px;
    font-weight: 600;
    padding: 10px;
    color: color(shade_1);
    display: flex;
    .dropdown-item {
      text-overflow: ellipsis;
      overflow: hidden;
      margin-right: 10px;
      line-height: 16px;
    }
  }

  .dropdown-item.selected {
    line-height: 1.2;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

p-dropdown {
  .p-dropdown {
    transition: all 0.2s ease-in-out;
    &:hover {
      background: color(shade_7);
    }
  }

  &.borderless {
    .p-dropdown {
      border: none;
      background: color(shade_7);
    }
  }

  &.transparent {
    .p-dropdown {
      background: transparent;
    }
  }

  //&.opened {
  //  .p-dropdown {
  //    background: color(shade_7);
  //  }
  //}
}

.wider-dropdown .p-float-label p-multiselect .p-component {
  max-width: 23rem !important;
}

p-multiSelect {
  //&.opened {
  //  .p-multiselect {
  //    background: color(shade_7);
  //  }
  //}
  .p-multiselect {
    transition: all 0.2s ease-in-out;
    &:hover {
      background: color(white);
    }

    .p-multiselect-label {
      @extend .p-inputtext;
      border: none;
    }
    .p-multiselect-item > div:nth-child(2) {
      font-size: 14px;
      line-height: 1;
      margin-bottom: 2px;
    }
  }
}

//.p-checkbox .p-checkbox-box {
//  border: 1px solid color(color_secondary);
//  box-sizing: border-box;
//  border-radius: 5px;
//}

.p-inputwrapper {
  //width: calc(100% - 1px);

  .p-dropdown {
    width: 100%;
  }
}

// for the multi dropdowns which should show a specific message if they're empty
#skillhop-app .dropdown-specific-empty-message {
  p-multiSelect {
    .p-multiselect-label-empty {
      &::after {
        content: 'All selected';
        visibility: visible;
        position: absolute;
        left: 10px;
      }
    }
  }

  // empty here means all are selected
  &.cashflow-properties p-multiSelect .p-multiselect-label-empty::after {
    content: 'All Properties';
  }
  &.cashflow-projects p-multiSelect .p-multiselect-label-empty::after {
    content: 'All Projects';
  }
}

.spend-app-dropdown.commitment-input {
  .p-dropdown {
    &.p-disabled {
      background: color(color_accent_shade_4);
      opacity: 1;
    }
  }
}

// -------------------------
/* Input Switch Styles */
// -------------------------
p-inputSwitch {
  // general switch, big size
  .p-inputswitch {
    display: flex;
    .p-inputswitch-slider {
      scale: 0.8;
      background: color(shade_4);
    }
    /* Focus Styles */
    &.p-focus .p-inputswitch-slider {
      box-shadow: none;
    }

    .p-inputswitch-slider:before {
      background: color(white);
    }

    /* Checked Styles */
    &.p-inputswitch-checked {
      .p-inputswitch-slider {
        background: color(color_secondary);
      }
      .p-inputswitch-slider:before {
        background: color(white);
      }
      &:not(.p-disabled):hover {
        .p-inputswitch-slider {
          //background: color(shade_1);
          opacity: 0.8;
        }
      }
    }
  }

  // smaller switch
  &.small .p-inputswitch {
    height: 0.8rem;
    width: 1.8rem;

    .p-inputswitch-slider:before {
      width: 1rem;
      height: 1rem;
      left: 0;
      margin-top: -0.5rem;
    }
    &.p-inputswitch-checked .p-inputswitch-slider:before {
      transform: translateX(0.9rem);
    }
  }

  &.light-blue-switch .p-inputswitch {
    .p-inputswitch-slider {
      background: color(shade_4);
    }
    .p-inputswitch-slider:before {
      background: color(color_secondary);
      left: 0;
    }
    &:not(.p-disabled):hover {
      .p-inputswitch-slider {
        background: color(shade_3);
      }
    }

    /* Checked Styles */
    &.p-inputswitch-checked {
      .p-inputswitch-slider {
        background: color(shade_3);
      }
      .p-inputswitch-slider:before {
        background: color(color_secondary);
      }
      &:not(.p-disabled):hover {
        .p-inputswitch-slider {
          background: color(shade_2);
        }
      }
    }
  }
}

.mat-calendar-body-cell {
  box-sizing: border-box;
}

// -------------------------
// checkbox styles
// -------------------------

.p-checkbox {
  .p-hidden-accessible {
    display: none;
  }
  .p-checkbox-box {
    border-radius: 5px;
    border: 2px solid color(color_secondary);
    box-sizing: border-box;

    &.p-disabled {
      border-color: color(shade_3);
      opacity: 1;
      cursor: not-allowed;
    }
  }
}

p-checkbox {
  &.small {
    .p-checkbox {
      $size: 18px;
      width: $size;
      height: $size;
      .p-checkbox-box {
        width: $size;
        height: $size;
        .p-icon {
        }
      }
    }
  }
}

.p-overlay.p-component {
  z-index: 1051 !important;
  //min-width: 150px !important;
}

// -------------------------
// custom calendar styles
// -------------------------

.prime-calendar-spend {
  .p-inputtext {
    padding: 10px 0 10px 10px;
    font-size: 12px !important;
    font-weight: 600;

    &:enabled:focus,
    &:enabled:hover {
      border-color: color(shade_5);
      box-shadow: none;
    }
  }

  &.commitment-input .p-disabled {
    opacity: 1;
    .p-inputtext {
      background: color(color_accent_shade_4);
    }
  }
}

.prime-calendar-gantt {
  .p-inputtext {
    padding: 10px 5px;
    font-size: 13px;
    border: none;
    text-align: center;

    &:enabled:focus {
      box-shadow: none;
      border-color: transparent;
    }
  }
}

.prime-border {
  border: 1px solid color(shade_5);
  border-radius: 3px;
  padding: 10px;
  margin-top: 3px;
  transition: all 0.3s ease-in-out;

  &:hover {
    border-color: color(color_secondary);
  }

  input {
    font-weight: 600;
    outline: none;
  }
}

#skillhop-app .icon-remove {
  @extend .icon-add;
  @apply cursor-pointer hover:text-color_reject bg-white;
  &:before {
    transform: rotate(45deg) translate(-1px, -1px);
  }
}

// -------------------------
// progress bar styles
// -------------------------

.p-progressbar {
  height: 6px;
  border-radius: 0;
  .p-progressbar-value {
    background: color(shade_2);
  }
}

// -------------------------
// tooltip styles
// -------------------------

.p-tooltip {
  // default color
  --tooltip-bg-color: #{color(shade_1)};
}

.p-tooltip.negative {
  // color for negative tooltips, --tooltip-bg-color can be changed dynamically
  --tooltip-bg-color: #{color(white)};

  .p-tooltip-text {
    // add left and right parentheses to the negative tooltip
    @include globalMixins.negative();
  }
}

.p-tooltip-arrow {
  border: none !important;
  width: 8px;
  height: 8px;
  background: var(--tooltip-bg-color);
  border-radius: 1px;
  margin: unset;
}

$tooltip-offset: 4px;

.p-tooltip-top {
  transform: translate(0px, -$tooltip-offset);
  .p-tooltip-arrow {
    transform: translate(-2px, -1px) rotate(45deg);
  }
}

.p-tooltip-bottom {
  transform: translate(0px, $tooltip-offset);
  .p-tooltip-arrow {
    transform: translate(-2px, 1px) rotate(45deg);
  }
}

.p-tooltip-left {
  transform: translate(-$tooltip-offset, 0px);
  .p-tooltip-arrow {
    transform: translate(-1px, -2px) rotate(45deg);
  }
}

.p-tooltip-right {
  transform: translate($tooltip-offset, 0px);
  .p-tooltip-arrow {
    transform: translate(1px, -2px) rotate(45deg);
  }
}

.p-tooltip .p-tooltip-text {
  border-radius: 3px;
  background: var(--tooltip-bg-color);
  width: fit-content;
  font-size: $font_size_xl;
}

.p-tooltip.p-component {
  min-width: fit-content;
}
