// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
//@import '@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
/* TODO(mdc-migration): Remove all-legacy-component-typographies once all legacy components are migrated*/
//@include mat.all-legacy-component-typographies();
@include mat.all-component-typographies();
/* TODO(mdc-migration): Remove legacy-core once all legacy components are migrated*/
//@include mat.legacy-core();
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$skillhop-primary-palette: (
  50: #e1e3e8,
  100: #b5b9c7,
  200: #838ba1,
  300: #515d7b,
  400: #2c3a5f,
  500: #071743,
  600: #06143d,
  700: #051134,
  800: #040d2c,
  900: #02071e,
  A100: #5b6eff,
  A200: #2841ff,
  A400: #001df4,
  A700: #001ada,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$skillhop-secondary-palette: (
  50: #e8f2fd,
  100: #c7defb,
  200: #a1c9f8,
  300: #7bb3f5,
  400: #5fa2f3,
  500: #4392f1,
  600: #3d8aef,
  700: #347fed,
  800: #2c75eb,
  900: #1e63e7,
  A100: #ffffff,
  A200: #e9f0ff,
  A400: #b6cdff,
  A700: #9cbbff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$skillhop-primary: mat.m2-define-palette($skillhop-primary-palette);
$skillhop-accent: mat.m2-define-palette($skillhop-secondary-palette);
$skillhop-warn: mat.m2-define-palette(mat.$m2-red-palette);

$skillhop-typography: mat.m2-define-typography-config(
  $font-family: 'Titillium Web, sans-serif',
);

// Create the theme object (a Sass map containing all of the palettes).
$skillhop-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $skillhop-primary,
      accent: $skillhop-accent,
      warn: $skillhop-warn,
    ),
    typography: $skillhop-typography,
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
/* TODO(mdc-migration): Remove all-legacy-component-themes once all legacy components are migrated*/
//@include mat.all-legacy-component-themes($skillhop-theme);
@include mat.all-component-themes($skillhop-theme);
