@use 'src/styles' as *;

.bg-secondary {
  background-color: color(color_secondary) !important;
}

.bg-primary {
  background-color: color(color_primary) !important;
}

.bg-pink {
  background-color: color(red_400) !important;
}

.bg-accept {
  background-color: color(color_accept) !important;
}

.bg-reject {
  background-color: color(color_reject) !important;
}

.bg-accent {
  background-color: color(color_accent) !important;
}

.bg-cancel {
  background-color: color(shade_3) !important;
}

.bg-1 {
  background-color: color(shade_2) !important;
}

.bg-completed {
  background-color: color(purple_500) !important;
}

.bg-progress {
  background-color: color(color_purple_2) !important;
}

.color-accept {
  color: color(color_accept) !important;
}

.color-light-green {
  color: color(color_light_green) !important;
}

.color-reject {
  color: color(color_reject) !important;
}

.color-primary {
  color: color(color_primary) !important;
}

.color-secondary {
  color: color(color_secondary) !important;
}

.color-pink {
  color: color(red_400) !important;
}

.color-cancel {
  color: color(shade_3) !important;
}

.color-0 {
  color: color(shade_1) !important;
}

.color-1 {
  color: color(shade_2) !important;
}
.color-2 {
  color: color(shade_3) !important;
}

.color-3 {
  color: color(shade_4) !important;
}

.color-white {
  color: white !important;
}

.disp-none {
  display: none !important;
}

.color-disabled {
  color: color(color_grey);
  -webkit-text-fill-color: color(color_grey); // for safari...
}

.visibility-none {
  visibility: hidden;
  height: 0px;
}

.visibility-hidden {
  visibility: hidden;
}

.default-cursor {
  cursor: default !important;
}

.no-border {
  border: none;
}

.no-border:focus-visible {
  border: none;
  outline: none;
}

.transparent-background {
  background: transparent;
}

.text-cursor {
  cursor: text;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-capitalize {
  text-transform: capitalize;
}

.text-bold {
  font-weight: bold;
}

.pointer-none {
  pointer-events: none;
}

.pointer-cursor {
  cursor: pointer;
}

.drag-cursor {
  cursor: grab;
}

.user-select-none {
  -webkit-user-select: none;
  user-select: none;
}

.overflow-hidden {
  overflow: hidden;
}

.no-opacity {
  opacity: 0;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.negative-color {
  .mat-input-element:not(:focus),
  &.p-inputtext:enabled:not(:focus),
  & .p-inputtext:enabled:not(:focus) {
    color: color(color_red_negative) !important;
  }
}
