@use 'src/styles' as *;

.tos-dialog-panel {
  max-width: 90vw !important;
  @screen md {
    max-width: 80vw !important;
  }
  .mat-dialog-container {
    border-radius: 20px;
  }
}

.tosBackdrop {
  backdrop-filter: blur(2px);
  filter: brightness(0.9);
}

.custom-overlay-backdrop {
  opacity: 0;
}

::ng-deep .skillhop-overlay-container {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1000;

  @media only screen and (max-width: $menubar_breakpoint) {
    left: 0;
  }
}

.cdk-overlay-dark-backdrop {
  background: rgba(6, 26, 79, 0.6);
}

.cdk-overlay-connected-position-bounding-box {
  overflow: visible !important;
}

.options-overlay-white {
  font-size: 12px;
  color: color(color_primary) !important;
  background: color(white);
  box-shadow: 0 3px 10px rgba(6, 26, 79, 0.1);
}
