@use 'src/styles' as *;

* {
  // needs after primeNgTheme.css import
  box-sizing: content-box;
}

* {
  //-ms-overflow-style: none; /* IE and Edge */
  //scrollbar-width: none; /* Firefox */
  .cdk-virtual-scroll-viewport {
    scrollbar-width: thin;
    scrollbar-color: color(shade_2) transparent;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

html,
body {
  height: 100%;
}
body {
  font-weight: unset;
}

body {
  margin: 0;
  font-family: Titillium Web !important;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  font-size: initial !important;
  font-weight: unset !important;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: never;

  ::-webkit-scrollbar {
    width: 0px !important; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
}

::-webkit-scrollbar {
  display: none;
}

a {
  color: unset;
  cursor: pointer;
  &:hover {
    color: unset;
  }
}
