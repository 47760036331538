@import 'src/assets/fonts/new-fw-icons-codes.css';

@font-face {
  font-family: 'fw-icons';
  src: url('/assets/fonts/old-fontello-icons/framework-icons.ttf')
    format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'new-fw-icons';
  src: url('/assets/fonts/new-fw-icons.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?36593548#fontello') format('svg');
  }
}
*/

[class^='icon-']:before,
[class*=' icon-']:before {
  font-family: 'new-fw-icons';
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-projects-old:before {
  font-family: 'fw-icons';
  content: '\e800';
} /* '' */
.icon-settings:before {
  font-family: 'fw-icons';
  content: '\e801';
} /* '' */
.icon-logo:before {
  font-family: 'fw-icons';
  content: '\e802';
} /* '' */
.icon-documents:before {
  font-family: 'fw-icons';
  content: '\e803';
} /* '' */
.icon-budget:before {
  font-family: 'fw-icons';
  content: '\e804';
} /* '' */
.icon-contractors:before {
  font-family: 'fw-icons';
  content: '\e805';
} /* '' */
.icon-dashboard:before {
  font-family: 'fw-icons';
  content: '\e806';
} /* '' */
.icon-check:before {
  font-family: 'fw-icons';
  content: '\e807';
} /* '' */
.icon-checkbox-round:before {
  font-family: 'fw-icons';
  content: '\e80c';
} /* '' */
.icon-star:before {
  font-family: 'fw-icons';
  content: '\e810';
} /* '' */
.icon-menu-add:before {
  font-family: 'fw-icons';
  content: '\e812';
} /* '' */
.icon-add:before {
  font-family: 'fw-icons';
  content: '\e814' !important;
} /* '' */
.icon-checkbox:before {
  font-family: 'fw-icons';
  content: '\e81d';
} /* '' */
.icon-checkbox-u:before {
  font-family: 'fw-icons';
  content: '\e81f';
} /* '' */
.icon-search:before {
  font-family: 'fw-icons';
  content: '\e820';
} /* '' */
.icon-star-u:before {
  font-family: 'fw-icons';
  content: '\e821';
} /* '' */
.icon-checkbox-round-u:before {
  font-family: 'fw-icons';
  content: '\e822';
} /* '' */
.icon-add-round:before {
  font-family: 'fw-icons';
  content: '\e823';
} /* '' */
.icon-options:before {
  font-family: 'fw-icons';
  content: '\e825';
  width: unset;
} /* '' */
.icon-pin:before {
  font-family: 'fw-icons';
  content: '\e826';
} /* '' */
.icon-print:before {
  font-family: 'fw-icons';
  content: '\e827';
} /* '' */
.icon-remove:before {
  font-family: 'fw-icons';
  content: '\e828';
} /* '' */
.icon-share:before {
  font-family: 'fw-icons';
  content: '\e829';
} /* '' */
.icon-calendar:before {
  font-family: 'fw-icons';
  content: '\e82a';
} /* '' */
.icon-schedule:before {
  font-family: 'fw-icons';
  content: '\e82b';
} /* '' */
.icon-send:before {
  font-family: 'fw-icons';
  content: '\e82c';
} /* '' */
.icon-arrow-right:before {
  font-family: 'fw-icons';
  content: '\e82d';
} /* '' */
.icon-email:before {
  font-family: 'fw-icons';
  content: '\e82e';
} /* '' */
.icon-alarm-bell:before {
  font-family: 'fw-icons';
  content: '\e82f';
} /* '' */
.icon-meetings:before {
  font-family: 'fw-icons';
  content: '\e830';
} /* '' */
.icon-menu-back:before {
  font-family: 'fw-icons';
  content: '\e831';
} /* '' */
.icon-menu-back:before {
  font-family: 'fw-icons';
  content: '\e831';
} /* '' */
.icon-bid-hammer:before {
  font-family: 'fw-icons';
  content: '\e808';
}
.icon-schedule-full:before {
  font-family: 'fw-icons';
  content: '\e809';
} /* '' */
.icon-invoices:before {
  font-family: 'fw-icons';
  content: '\e80a';
} /* '' */
.icon-notes:before {
  font-family: 'fw-icons';
  content: '\e80b';
} /* '' */
.icon-spend:before {
  font-family: 'fw-icons';
  content: '\e80d';
} /* '' */
.icon-upload:before {
  font-family: 'fw-icons';
  content: '\e824';
}
.icon-refresh:before {
  font-family: 'fw-icons';
  content: '\e85d';
  margin: 0;
}
.icon-double-checkmark:before {
  font-family: 'fw-icons';
  content: '\e85e';
}
.icon-upload:before {
  font-family: 'fw-icons';
  content: '\e85f';
}

.icon-no-plans:before {
  font-family: 'fw-icons';
  content: '\e80E';
}
.icon-no-plans-u:before {
  font-family: 'fw-icons';
  content: '\e80F';
}
.icon-2d-u:before {
  font-family: 'fw-icons';
  content: '\e813';
}
.icon-2d:before {
  font-family: 'fw-icons';
  content: '\e811';
}
.icon-3d:before {
  font-family: 'fw-icons';
  content: '\e815';
}
.icon-3d-u:before {
  font-family: 'fw-icons';
  content: '\e816';
}

.icon-multifamily-u:before {
  font-family: 'fw-icons';
  content: '\e817';
} /* '' */
.icon-multifamily:before {
  font-family: 'fw-icons';
  content: '\e818';
} /* '' */
.icon-single-fam:before {
  font-family: 'fw-icons';
  content: '\e819';
} /* '' */
.icon-single-fam-u:before {
  font-family: 'fw-icons';
  content: '\e81a';
} /* '' */
.icon-medical-u:before {
  font-family: 'fw-icons';
  content: '\e81b';
} /* '' */
.icon-medical:before {
  font-family: 'fw-icons';
  content: '\e81c';
} /* '' */
.icon-office-u:before {
  font-family: 'fw-icons';
  content: '\e81e';
} /* '' */
.icon-office:before {
  font-family: 'fw-icons';
  content: '\e832';
} /* '' */
.icon-retail-u:before {
  font-family: 'fw-icons';
  content: '\e833';
} /* '' */
.icon-retail:before {
  font-family: 'fw-icons';
  content: '\e834';
} /* '' */
.icon-exterior-u:before {
  font-family: 'fw-icons';
  content: '\e835';
} /* '' */
.icon-exterior:before {
  font-family: 'fw-icons';
  content: '\e836';
} /* '' */
.icon-doors-u:before {
  font-family: 'fw-icons';
  content: '\e837';
} /* '' */
.icon-doors:before {
  font-family: 'fw-icons';
  content: '\e838';
} /* '' */
.icon-landscaping-u:before {
  font-family: 'fw-icons';
  content: '\e839';
} /* '' */
.icon-landscaping:before {
  font-family: 'fw-icons';
  content: '\e83a';
} /* '' */
.icon-parking-u:before {
  font-family: 'fw-icons';
  content: '\e83b';
} /* '' */
.icon-parking:before {
  font-family: 'fw-icons';
  content: '\e83c';
} /* '' */
.icon-pool:before {
  font-family: 'fw-icons';
  content: '\e83d';
} /* '' */
.icon-remodel-u:before {
  font-family: 'fw-icons';
  content: '\e83e';
} /* '' */
.icon-remodel:before {
  font-family: 'fw-icons';
  content: '\e83f';
} /* '' */
.icon-kitchen-u:before {
  font-family: 'fw-icons';
  content: '\e840';
} /* '' */
.icon-kitchen:before {
  font-family: 'fw-icons';
  content: '\e841';
} /* '' */
.icon-bathroom-u:before {
  font-family: 'fw-icons';
  content: '\e842';
} /* '' */
.icon-bathroom:before {
  font-family: 'fw-icons';
  content: '\e843';
} /* '' */
.icon-bedroom-u:before {
  font-family: 'fw-icons';
  content: '\e844';
} /* '' */
.icon-bedroom:before {
  font-family: 'fw-icons';
  content: '\e845';
} /* '' */
.icon-living-room-u:before {
  font-family: 'fw-icons';
  content: '\e846';
} /* '' */
.icon-living-room:before {
  font-family: 'fw-icons';
  content: '\e847';
} /* '' */
.icon-appliances-u:before {
  font-family: 'fw-icons';
  content: '\e848';
} /* '' */
.icon-appliances:before {
  font-family: 'fw-icons';
  content: '\e849';
} /* '' */
.icon-counters-u:before {
  font-family: 'fw-icons';
  content: '\e84a';
} /* '' */
.icon-counters:before {
  font-family: 'fw-icons';
  content: '\e84b';
} /* '' */
.icon-light-fixtures-u:before {
  font-family: 'fw-icons';
  content: '\e84c';
} /* '' */
.icon-light-fixtures:before {
  font-family: 'fw-icons';
  content: '\e84d';
} /* '' */
.icon-painting-u:before {
  font-family: 'fw-icons';
  content: '\e84e';
} /* '' */
.icon-painting:before {
  font-family: 'fw-icons';
  content: '\e84f';
} /* '' */
.icon-cabinets-u:before {
  font-family: 'fw-icons';
  content: '\e850';
} /* '' */
.icon-cabinets:before {
  font-family: 'fw-icons';
  content: '\e851';
} /* '' */
.icon-demolition-u:before {
  font-family: 'fw-icons';
  content: '\e852';
} /* '' */
.icon-demolition:before {
  font-family: 'fw-icons';
  content: '\e853';
} /* '' */
.icon-electrical-u:before {
  font-family: 'fw-icons';
  content: '\e854';
} /* '' */
.icon-electrical:before {
  font-family: 'fw-icons';
  content: '\e855';
} /* '' */
.icon-flooring-u:before {
  font-family: 'fw-icons';
  content: '\e856';
} /* '' */
.icon-flooring:before {
  font-family: 'fw-icons';
  content: '\e857';
} /* '' */
.icon-plumbing:before {
  font-family: 'fw-icons';
  content: '\e858';
} /* '' */
.icon-plumbing-u:before {
  font-family: 'fw-icons';
  content: '\e858';
} /* '' */
.icon-calculate-budget:before {
  font-family: 'fw-icons';
  content: '\e859';
} /* '' */
.icon-input-manually:before {
  font-family: 'fw-icons';
  content: '\e85a';
} /* '' */
.icon-area:before {
  font-family: 'fw-icons';
  content: '\e85b';
} /* '' */
.icon-suggested-budget:before {
  font-family: 'fw-icons';
  content: '\e85c';
} /* '' */

.icon-mechanical-u:before {
  font-family: 'fw-icons';
  content: '\e860';
} /* '' */
.icon-mechanical:before {
  font-family: 'fw-icons';
  content: '\e861';
} /* '' */
.icon-drywall-u:before {
  font-family: 'fw-icons';
  content: '\e862';
} /* '' */
.icon-drywall:before {
  font-family: 'fw-icons';
  content: '\e863';
} /* '' */
.icon-plumbing-fixtures-u:before {
  font-family: 'fw-icons';
  content: '\e864';
} /* '' */
.icon-plumbing-fixtures:before {
  font-family: 'fw-icons';
  content: '\e865';
} /* '' */
.icon-tilework-u:before {
  font-family: 'fw-icons';
  content: '\e866';
} /* '' */
.icon-tilework:before {
  font-family: 'fw-icons';
  content: '\e867';
} /* '' */
.icon-speciality-u:before {
  font-family: 'fw-icons';
  content: '\e868';
} /* '' */
.icon-speciality:before {
  font-family: 'fw-icons';
  content: '\e869';
} /* '' */
.icon-roofing-u:before {
  font-family: 'fw-icons';
  content: '\e86a';
} /* '' */
.icon-roofing:before {
  font-family: 'fw-icons';
  content: '\e86b';
} /* '' */
.icon-windows-u:before {
  font-family: 'fw-icons';
  content: '\e86c';
} /* '' */
.icon-windows:before {
  font-family: 'fw-icons';
  content: '\e86e';
} /* '' */
.icon-railings:before {
  font-family: 'fw-icons';
  content: '\e86f';
} /* '' */
.icon-paving-u:before {
  font-family: 'fw-icons';
  content: '\e870';
} /* '' */
.icon-stairs:before {
  font-family: 'fw-icons';
  content: '\e871';
} /* '' */
.icon-waterproofing-u:before {
  font-family: 'fw-icons';
  content: '\e872';
} /* '' */
.icon-waterproofing:before {
  font-family: 'fw-icons';
  content: '\e873';
} /* '' */
.icon-siding-u:before {
  font-family: 'fw-icons';
  content: '\e874';
} /* '' */
.icon-siding:before {
  font-family: 'fw-icons';
  content: '\e875';
} /* '' */
.icon-pool-1:before {
  font-family: 'fw-icons';
  content: '\e876';
} /* '' */
.icon-hvac-u:before {
  font-family: 'fw-icons';
  content: '\e877';
} /* '' */
.icon-hvac:before {
  font-family: 'fw-icons';
  content: '\e878';
} /* '' */
.icon-concrete-u:before {
  font-family: 'fw-icons';
  content: '\e879';
} /* '' */
.icon-concrete:before {
  font-family: 'fw-icons';
  content: '\e87a';
} /* '' */
.icon-fencing-u:before {
  font-family: 'fw-icons';
  content: '\e87b';
} /* '' */
.icon-fencing:before {
  font-family: 'fw-icons';
  content: '\e87e';
} /* '' */
.icon-gates-u:before {
  font-family: 'fw-icons';
  content: '\e87f';
} /* '' */
.icon-gates:before {
  font-family: 'fw-icons';
  content: '\e880';
} /* '' */
.icon-tree-removal-u:before {
  font-family: 'fw-icons';
  content: '\e881';
} /* '' */
.icon-tree-removal:before {
  font-family: 'fw-icons';
  content: '\e882';
} /* '' */
.icon-pool-rm-u:before {
  font-family: 'fw-icons';
  content: '\e883';
} /* '' */
.icon-pool-rm:before {
  font-family: 'fw-icons';
  content: '\e884';
} /* '' */
.icon-contact:before {
  font-family: 'fw-icons';
  content: '\e885';
} /* '' */
.icon-money-budget:before {
  font-family: 'fw-icons';
  content: '\e886';
} /* '' */
.icon-bathroom-accessories-u:before {
  font-family: 'fw-icons';
  content: '\E86D';
} /* '' */
.icon-bathroom-accessories:before {
  font-family: 'fw-icons';
  content: '\E87C';
} /* '' */

.icon-oshpd:before {
  font-family: 'fw-icons';
  content: '\E888';
} /* '' */
.icon-union-contractor:before {
  font-family: 'fw-icons';
  content: '\E887';
} /* '' */
.icon-prevailing-wage:before {
  font-family: 'fw-icons';
  content: '\E87D';
} /* '' */

.icon-youtube:before {
  font-family: 'fw-icons';
  content: '\E889';
} /* '' */
.icon-attention:before {
  font-family: 'fw-icons';
  content: '\E88B';
} /* '' */
.icon-capex:before {
  font-family: 'fw-icons';
  content: '\E917';
} /* '' */
.icon-opex:before {
  font-family: 'fw-icons';
  content: '\E918';
} /* '' */

.icon-check-mark:before {
  font-family: 'fw-icons';
  content: '\E899';
} /* '' */
