.settings-tab-group .mdc-tab {
  //mszabi [2023-sept-07] currently not used, please uncomment if used in future
  @apply text-color_secondary font-bold text-xs;
  &.mdc-tab-indicator--active {
    @apply text-shade_2;
  }
  .mdc-tab-indicator {
    @apply hidden;
  }
}
