$shadow-width: 16px;

.h-txt-overflow {
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  padding-right: $shadow-width;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: $shadow-width;
    height: 100%;
    background-image: (
      linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0%,
        white 80%,
        white 100%
      )
    );
    pointer-events: none;
  }
}
